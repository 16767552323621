import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const AreSwimsuitsCompulsory = () => (
  <Layout>
    <SEO title="Are swimsuits always compulsory in UK saunas?" />
    <h1>Are swimsuits always compulsory in UK saunas?</h1>
      <div className="entry-content">
          <p>This question, and ones like it, are asked often.</p>
          <p>The short answer is “Not always”, but a long answer is warranted.</p>
          <p>A number of UK saunas do state the rule “Swimwear must be worn” – either inside the sauna itself, or
              alongside the opening hours. Usually the full line is “This is a mixed sauna therefore swimwear must be
              worn at all times”. There are even saunas that have gender-segregated sessions which still carry the
              “swimwear must be worn” rule.</p>
          <p>As a result, there is a widely-held belief that all UK mixed saunas require swimwear. This is not true, as
              we will see later.</p>
          <p>Let us consider another situation. In a large number of German saunas, swimwear is banned. However, it is
              not true to say that every German sauna requires nudity. There are, in fact, some German poolside saunas
              that have the same dress code as the pool – swimwear must be worn.</p>
          <p>The dress code difference between these two nations is a general trend, not a hard rule.</p>
          <p>Perhaps the most obvious time when swimwear is not compulsory in a UK sauna is during “naturist” sessions.
              A number of leisure centres host naturist sessions, either in time hired by a naturist club or (in the
              case of Selby) as part of the normal schedule. Some naturist clubs have their own venues. In all of these,
              nudity is expected.</p>
          <p>This leaves a large number of “unlabelled” sessions at saunas across the country. At these, mixed exposed
              nudity is not commonplace – but the opposite of exposed nudity is not swimwear. There is another way –
              wrapping yourself in a towel.</p>
          <p>It’s important to remember that sitting naked directly on a sauna bench is not great for hygiene. It is
              best to sit or lie on a towel. In some countries, you are expected to ensure that no part of your body
              touches the benches – even your feet should be on a towel. As a result, it is difficult to wrap the same
              towel around your body and so the easiest and most practical outcome is that you expose your body. This
              exposure has benefits for your skin and the effects of the sauna. The nudity has a purpose which is not
              sexual. Such countries still have sexual nudity in other situations, but the sauna area is clearly
              designated as an area where mixed nudity is expected and therefore not provocative or shocking. Typically
              this area will have distinct physical boundaries separating it from a ‘textile’ pool where swimwear is
              required.</p>
          <p>In the UK, the most typical usage of a sauna is to sit in a swimsuit with no towel. In part, the layout of
              facilities encourages this. Poolside saunas are the most common, and the most direct path between pool (or
              spa pool) and the sauna offers at best an open shower. Rarely is there a suitable place to undress. The
              easiest and most practical outcome of placing a sauna within a swimming context is that people sit in the
              sauna in their swimwear.</p>
          <p>Some venues have a sauna for each gender. This is typically in or alongside the changing room. Within this
              context people are in various states of undress, and this is reflected in the sauna. A swimsuit is rarely
              compulsory here, but still not everyone will go naked. There will be people in underwear, swimwear,
              wrapped towels and open towels – just like in the changing room itself.</p>
          <p>While the poolside sauna is most easily taken in a swimsuit, there are people whose preference to sauna
              without swimwear will drive them to make the additional effort. They will take the time to go back to the
              changing room, undress, shower, and wrap themselves in a towel before they go into the sauna. Equally,
              there are people who have no intention of using the pool, such as gym users, who will go from the gym to
              the sauna via the changing room.</p>
          <p>If you are covered by a towel in such a way that it is impossible to tell whether you are wearing a
              swimsuit underneath, you are unlikely to be challenged. Indeed, even where a “swimwear must be worn” rule
              is displayed, this is often taken to mean “cover up” rather than literally requiring swimwear.</p>
          <p>So in short, <strong>it is not always compulsory to wear a swimsuit in a British sauna, and you’ll
              practically always be OK in a wrapped towel.</strong></p>
          <p>Which leads us to the question “Is it always necessary to cover up in UK mixed saunas?” which we will
              answer another day.</p>
          <p>As a funky alternative to the towel, you may prefer a <a href="https://amzn.to/2Tt7Sua">Sauna
              Wrap</a>.</p>
      </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AreSwimsuitsCompulsory
